<template>
  <div class="loader-overlay">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>
