<template>
  <div id="app" class="app-body" :class="{ fantasysportquiz: fantasysportquiz }">
    <div class="app-body__wrapper">
      <Header v-if="loading || error" />
      <Loader v-if="loading && !error" />
      <div :id="fantasysportquiz ? 'scriptContainer2' : 'scriptContainer'" class="bc-placeholder"></div>
      <router-view v-if="!loading" :key="$route.name" />
      <div v-if="!loading && locale !== null" class="updated">
        {{ this.$store.state.options.staticContent.trUpdated }}: {{ filterTimeFormat(this.$store.state.options.generalSettings.pageUpdate) }}
      </div>
    </div>
    <Footer v-if="!loading" />
    <div v-if="error" class="error-body">
      <div class="content">
        <h1>ERROR 404</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Footer,
  },
  data() {
    return {
      // extraClass: '',
      fantasysportquiz: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    locale() {
      return this.$store.state.options.generalSettings.localeCode;
    },
    error() {
      return this.$store.state.error;
    },
  },
  methods: {
    ...mapActions([
      'saveUserPicks',
    ]),
    filterTimeFormat(time) {
      return moment(time * 1000).format('dddd, MMMM Do YYYY, h:mm:ss a');
    },
    // Check local storage for user picks (in case the user refreshes the app after the picks page)
    checkUserPicksExist() {
      const userPicks = sessionStorage.getItem('userPicks');
      // Get userpicks from local storage if it exists
      if (userPicks !== null) {
        this.saveUserPicks(JSON.parse(userPicks));
      }
    },
  },
  created() {
    window.dataLayer = window.dataLayer || [];
    this.checkUserPicksExist();
    if (window.location.hostname === 'fantasy-sportquiz.com') {
      this.fantasysportquiz = true;
    }
  },
  watch: {
    locale(newValue) {
      moment.locale(newValue);
    },
  },
};
</script>

<style lang="scss">
.site-logo {
  background: $main;
  img {
    display: block;
    margin: 0 auto;
    padding: .4rem 0;
    width: 170px;
  }
}
.error-body {
  text-align: center;
  padding: 4rem 0;
}
</style>
