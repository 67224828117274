// Global CSS styles
import '@/css/main.scss';

import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router';
import store from './store';
import Loader from './components/Loader.vue';
import Header from './components/Header.vue';
// Global components
const app = createApp(App);

// app.config.devtools = process.env.NODE_ENV === 'development';
app.component('Loader', Loader).component('Header', Header);
app.use(VueAxios, axios).use(store).use(router)
  .mount('#app');
