<template>
  <div class="landing-page">
    <!-- <div id="scriptContainer" class="bc-placeholder"></div> -->
    <img v-if="landingPageContent.adbanner" :src="landingPageContent.adbanner">
    <img v-if="landingPageContent.banner" :src="landingPageContent.banner">
    <div class="cta">
      <button @click="enter" class="button button--main">{{ landingPageContent.trLpMainButton }}</button>
    </div>
    <div v-if="landingPageContent.contentAboveSteps">
      <div v-html="landingPageContent.contentAboveSteps" class="above-steps-content content"></div>
    </div>
    <div v-if="landingPageContent.steps.length">
      <div v-for="(step, index) in landingPageContent.steps" :key="index" class="step">
        <div class="column-left">
          <div class="step__label">{{ landingPageContent.trStep }}</div>
          <span class="step__number">{{ index + 1 }}</span>
        </div>
        <div class="step__desc">{{ step.step_name }}</div>
      </div>
    </div>
    <div v-if="landingPageContent.features.length" class="features-box">
      <div v-for="(feature, index) in landingPageContent.features" :key="index" class="feature"
        :class="{ wide: landingPageContent.features.length < 3 }">
        <div v-if="feature.icon === 'flag'" class="feature-icon"><img
            :src="require(`@/assets/${feature.icon}-${landingPageContent.currentGeo}.png`)" alt="flag"></div>
        <div v-else class="feature-icon"><img :src="require(`@/assets/${feature.icon}.png`)" alt="icon"></div>
        <div class="feature-label">{{ feature.label }}</div>
      </div>
    </div>
    <div class="box">
      <div class="box--ia"></div>
    </div>
    <div class="lp-content-wrapper" v-html="landingPageContent.content"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LandingPage',
  data() {
    return {
      polling: null,
      hasIaCookie: false,
    };
  },
  computed: {
    ...mapGetters([
      'landingPageContent',
    ]),
    picksPath() {
      return `${this.landingPageContent.sportTypeSlug}/${this.landingPageContent.currentGeo}/picks`;
    },
  },
  methods: {
    enter() {
      window.dataLayer.push({
        event: 'Form step',
        stepNo: 'step 1',
        stepName: 'Join for free',
      });
      this.$router.push({ path: this.picksPath, query: { ...this.$route.query } });
    },
    checkForIaCookie() {
      if (this.hasIaCookie) return;
      this.polling = setInterval(() => {
        if (document.cookie.includes('_bc_int_ads_megafreebet.com')) {
          window.dataLayer.push({
            event: 'setCookie',
          });
          console.log('cookie present');
          clearInterval(this.polling);
        }
      }, 1000);
    },
  },
  created() {
    this.hasIaCookie = document.cookie.includes('_bc_int_ads_megafreebet.com');
  },
  mounted() {
    this.checkForIaCookie();
  },
};
</script>

<style lang="scss">
.landing-page img {
  display: block;
}

.column-left {
  display: flex;
  align-items: center;
}

.step__label {
  color: #32aa45;
  margin-bottom: 0;
  padding: 2px;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
}

.step {
  display: flex;
  padding: .6rem;
  font-size: 12px;
  align-items: center;

  &:nth-child(odd) {
    background: #ebebeb;
  }
}

.step__number {
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #32aa45;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-weight: 700;
  line-height: 0;
}

.step__desc {
  color: #4a4a4a;
  font-size: 12px;
}

.features-box {
  display: flex;
  justify-content: space-evenly;
  padding: 1rem;

  img {
    width: 30px;
  }
}

.feature {
  flex-basis: calc(33% - .5rem);

  &.wide {
    flex-basis: calc(50% - .5rem);
  }
}

.feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
}

.feature-label {
  font-size: 12px;
  text-align: center;
  color: #4a4a4a;
}

.cta {
  padding: .5rem;
}

.above-steps-content {
  padding: 0 .5rem .5rem;
  color: #4a4a4a;
  text-align: center;

  p {
    font-size: 14px;
  }
}
</style>
