import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import Home from '../views/Home.vue';
import LandingPage from '../views/LandingPage.vue';
import getParameterByName from '../helpers/getParameterByName';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/:geo',
    alias: ['/esports/:geo', '/cricket/:geo', '/basketball/:geo', '/:geo/test', '/:geo/2', '/:geo/cpl', '/:geo/hw'],
    name: 'LandingPage',
    component: LandingPage,
  },
  {
    path: '/:geo/picks',
    alias: ['/esports/:geo/picks', '/cricket/:geo/picks', '/basketball/:geo/picks'],
    name: 'Picks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Picks" */ '../views/Picks.vue'),
  },
  {
    path: '/:geo/cookies',
    alias: ['/esports/:geo/cookies', '/cricket/:geo/cookies', '/basketball/:geo/cookies'],
    name: 'Cookies',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Cookies" */ '../views/ContentPage.vue'),
  },
  {
    path: '/:geo/privacy',
    alias: ['/esports/:geo/privacy', '/cricket/:geo/privacy', '/basketball/:geo/privacy'],
    name: 'Privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Privacy" */ '../views/ContentPage.vue'),
  },
  {
    path: '/:geo/about',
    alias: ['/esports/:geo/about', '/cricket/:geo/about', '/basketball/:geo/about'],
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "About" */ '../views/ContentPage.vue'),
  },
  {
    path: '/:geo/contact',
    alias: ['/esports/:geo/contact', '/cricket/:geo/contact', '/basketball/:geo/contact'],
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Contact" */ '../views/ContentPage.vue'),
  },
  {
    path: '/:geo/terms',
    alias: ['/esports/:geo/terms', '/cricket/:geo/terms', '/basketball/:geo/terms'],
    name: 'Terms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Terms" */ '../views/ContentPage.vue'),
  },
  {
    path: '/:geo/submit',
    alias: ['/esports/:geo/submit', '/cricket/:geo/submit', '/basketball/:geo/submit'],
    name: 'Submit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Submit" */ '../views/Submit.vue'),
  },
  {
    path: '/:geo/thank-you',
    alias: ['/esports/:geo/thank-you', '/cricket/:geo/thank-you', '/basketball/:geo/thank-you'],
    name: 'ThankYou',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ThankYou" */ '../views/ThankYou.vue'),
  },
  {
    path: '/:geo/your-picks/:id',
    alias: ['/esports/:geo/your-picks/:id', '/cricket/:geo/your-picks/:id', '/basketball/:geo/your-picks/:id'],
    name: 'YourPicks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "YourPicks" */ '../views/YourPicks.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
});

// DYNAMIC APP TITLE
router.beforeEach((to, from, next) => {
  const siteHostname = window.location.hostname;
  const slicedHost = siteHostname.slice(0, siteHostname.length - 4);
  document.title = slicedHost.toUpperCase();
  next();
});

// Get global static content before each route change (if not already fetched)
router.beforeEach((to) => {
  // check for query params before route change
  const queryParamsPayload = {
    utmSource: getParameterByName('utm_source'),
    utmMedium: getParameterByName('utm_medium'),
    utmTerm: getParameterByName('utm_term'),
    utmContent: getParameterByName('utm_content'),
    utmCampaign: getParameterByName('utm_campaign'),
    gclid: getParameterByName('gclid'),
    keyword: getParameterByName('keyword'),
    matchtype: getParameterByName('matchtype'),
    device: getParameterByName('device'),
    adpos: getParameterByName('adpos'),
    userAgent: navigator.userAgent,
    origin_url: window.location.hostname,
  };
  // Only attempt to save query params if the object inside state is empty
  if (Object.keys(store.state.queryParams).length === 0) store.dispatch('saveQueryParams', queryParamsPayload);

  // Define default sport types array
  const sportTypes = ['esports', 'football', 'cricket', 'basketball'];
  // Starter sport
  let defaultSportType = 'football';
  // Init sport type slug
  let sportTypeSlug = '';
  // Chunk full path into an array
  const chunkedRoutePath = to.fullPath.split('/');
  // console.log(chunkedRoutePath);
  // Check if anything from the chunked array exists in the sportTypes array
  const intersection = sportTypes.filter((x) => chunkedRoutePath.includes(x));
  // Change sport type and slug if there was an intersection
  if (intersection.length === 1) {
    const [sportType] = intersection;
    defaultSportType = sportType;
    sportTypeSlug = `/${sportType}`;
  }

  if (store.state.options.cc === null) {
    if (typeof to.params.geo === 'undefined') return;

    store.dispatch('getGlobalContent', {
      geo: to.params.geo,
      sportType: defaultSportType,
      sportTypeSlug,
    });
  }
});

export default router;
