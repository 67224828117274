<template>
  <div class="site-logo">
    <router-link :to="{ path: landingPagePath }">
      <!-- <img src="@/assets/mfb-logo.png" alt="mfb-logo"> -->
      <img v-if="landingPageContent.logo" :src="landingPageContent.logo">
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  computed: {
    ...mapGetters([
      'landingPageContent',
    ]),
    landingPagePath() {
      return `${this.$store.state.options.sportTypeSlug}/${this.$store.state.options.cc}`;
    },
  },
};
</script>

<style>
</style>
