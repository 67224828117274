import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    loading: true,
    error: false,
    options: {
      cc: null,
      sportType: 'football',
      sportTypeSlug: '',
      footer: {
        content: null,
        disclaimer: null,
      },
      landingPage: {
        adbanner: null,
        logo: null,
        banner: null,
        steps: [],
        features: [],
        contentAboveSteps: '',
      },
      staticContent: {
        trStep: '',
        trLpMainButton: '',
        trDays: '',
        trHours: '',
        trMinutes: '',
        trSeconds: '',
        trTimerLabel: '',
        trGuessScoreLabel: '',
        trGuessScoreRandomBtn: '',
        trSubmitButton: '',
        trUpdated: '',
        trAboveEmailLabel: '',
        trContestStartedMessage: '',
        trConfirmEmailBtn: '',
        trInvalidEmailError: '',
        trTerms: '',
        trNewsletter: '',
        trAgeVerification: '',
        trPersonalDataProcessing: '',
        trThankYouPageContent: '',
        trThankYouPageContentAfter: '',
        trYourPicksContent: '',
        trYourPicksContentAfter: '',
        trTimeToResult: '',
      },
      generalSettings: {
        mainBanner: null,
        bannerSlogan: '',
        startTime: '',
        endTime: '',
        localeCode: null,
        pageUpdate: '',
      },
      footerLinks: [],
      footerIcons: '',
      emailCheckboxes: [],
      emailSubmissionOverlay: {
        enableOverlay: false,
        overlayBanner: null,
        overlayBannerClickout: false,
      },
    },
    userPicks: {},
    queryParams: {},
  },
  mutations: {
    GET_GLOBAL_CONTENT(state, payload) {
      state.options.cc = payload.geo;
      state.options.sportType = payload.sportType;
      state.options.sportTypeSlug = payload.sportTypeSlug;
      state.options.footer = payload.data.footer;
      state.options.landingPage = payload.data.landingPage;
      state.options.staticContent = payload.data.staticContent;
      state.options.generalSettings = payload.data.generalSettings;
      state.options.footerLinks = payload.data.footerLinks;
      state.options.footerIcons = payload.data.footerIcons;
      state.options.emailCheckboxes = payload.data.emailCheckboxes;
      state.options.emailSubmissionOverlay = payload.data.emailSubmissionOverlay;

      // Loading finished
      state.loading = false;
    },
    SAVE_USER_PICKS(state, payload) {
      state.userPicks = payload;
    },
    SET_ERROR(state, payload) {
      state.error = payload;
    },
    SAVE_QUERY_PARAMS(state, payload) {
      state.queryParams = payload;
    },
  },
  actions: {
    async getGlobalContent({ commit }, payload) {
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_API}/options/v1/${payload.geo}/${payload.sportType}?domain=${window.location.hostname}`);
        commit('GET_GLOBAL_CONTENT', {
          data,
          geo: payload.geo,
          sportType: payload.sportType,
          sportTypeSlug: payload.sportTypeSlug,
        });
      } catch (error) {
        console.log(error);
        commit('SET_ERROR', true);
      }
    },
    saveUserPicks({ commit }, payload) {
      commit('SAVE_USER_PICKS', payload);
    },
    saveQueryParams({ commit }, payload) {
      commit('SAVE_QUERY_PARAMS', payload);
    },
  },
  getters: {
    landingPageContent(state) {
      return {
        currentGeo: state.options.cc,
        features: state.options.landingPage.features,
        adbanner: state.options.landingPage.adbanner,
        logo: state.options.landingPage.logo,
        banner: state.options.landingPage.banner,
        steps: state.options.landingPage.steps,
        trStep: state.options.staticContent.trStep,
        trLpMainButton: state.options.staticContent.trLpMainButton,
        content: state.options.landingPage.content,
        sportTypeSlug: state.options.sportTypeSlug,
        contentAboveSteps: state.options.landingPage.contentAboveSteps,
      };
    },
    timerTranslation(state) {
      return {
        trDays: state.options.staticContent.trDays,
        trHours: state.options.staticContent.trHours,
        trMinutes: state.options.staticContent.trMinutes,
        trSeconds: state.options.staticContent.trSeconds,
        trTimeToResult: state.options.staticContent.trTimeToResult,
        trTimerLabel: state.options.staticContent.trTimerLabel,
      };
    },
    gamesModulesContent(state) {
      return {
        trGuessScoreLabel: state.options.staticContent.trGuessScoreLabel,
        trGuessScoreRandomBtn: state.options.staticContent.trGuessScoreRandomBtn,
        trSubmitButton: state.options.staticContent.trSubmitButton,
        trUpdated: state.options.staticContent.trUpdated,
        localeCode: state.options.generalSettings.localeCode,
      };
    },
    routePath(state) {
      return {
        currentGeo: state.options.cc,
        sportTypeSlug: state.options.sportTypeSlug,
      };
    },
    emailCheckboxes(state) {
      return state.options.emailCheckboxes;
    },
    submitPageContent(state) {
      return {
        trAboveEmailLabel: state.options.staticContent.trAboveEmailLabel,
        trConfirmEmailBtn: state.options.staticContent.trConfirmEmailBtn,
        trTerms: state.options.staticContent.trTerms,
        trNewsletter: state.options.staticContent.trNewsletter,
        trAgeVerification: state.options.staticContent.trAgeVerification,
        trPersonalDataProcessing: state.options.staticContent.trPersonalDataProcessing,
        trInvalidEmailError: state.options.staticContent.trInvalidEmailError,
        trUserEntryExists: state.options.staticContent.trUserEntryExists,
        trContestStartedMessage: state.options.staticContent.trContestStartedMessage,
      };
    },
    thankYouPageContent(state) {
      return {
        trThankYouPageContent: state.options.staticContent.trThankYouPageContent,
        trThankYouPageContentAfter: state.options.staticContent.trThankYouPageContentAfter,
      };
    },
    yourPicksPageContent(state) {
      return {
        trYourPicksContent: state.options.staticContent.trYourPicksContent,
        trYourPicksContentAfter: state.options.staticContent.trYourPicksContentAfter,
      };
    },
    countdownTimer(state) {
      return {
        startTime: state.options.generalSettings.startTime,
        endTime: state.options.generalSettings.endTime,
      };
    },
    emailSubmissionOverlay(state) {
      return {
        enableOverlay: state.options.emailSubmissionOverlay.enableOverlay,
        overlayBanner: state.options.emailSubmissionOverlay.overlayBanner,
        overlayBannerClickout: state.options.emailSubmissionOverlay.overlayBannerClickout,
      };
    },
  },
});
